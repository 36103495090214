import React, { useState } from 'react';
import {
  MenuItem,
  Menu,
  IconButton,
  Grid,
  Typography,
  Stack,
  Box,
  Button,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const itemsPerPageOptions = [10, 20, 30, 40];

const Paginations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setAnchorEl(null); // Close the menu when a new value is selected
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const totalRows = 97;

  return (
    <Box>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{ bgcolor: '#F1F4FD' }}
      >
        <Grid item xl={2} md={2} sm={2} xs={2}>
          <Typography variant='body1' color='textSecondary' pl={2}>
            {`1-${Math.min(
              currentPage * rowsPerPage,
              totalRows
            )} of ${totalRows} `}
          </Typography>
        </Grid>
        <Grid
          item
          xl={6.8}
          sx={{ display: { xs: 'none', sm: 'none', md: 'none', xl: 'block' } }}
        >
          {/* Content for larger screens */}
        </Grid>
        <Grid item xl={2} md={6} sm={6} xs={4}>
          {` Rows per page:${rowsPerPage}`}
          <IconButton onClick={openMenu} size='small'>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {itemsPerPageOptions.map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  handleRowsPerPageChange({ target: { value: option } });
                  handleCloseMenu();
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xl={1.2} md={3} sm={3} xs={4}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              sx={{
                borderRadius: '10px',
                border: '1px solid gray',
                minWidth: '2rem',
                bgcolor: '#F7F9FC',
              }}
            >
              <KeyboardArrowLeftIcon
                sx={{ fontSize: '15px', fontWeight: '500' }}
              />
            </Button>
            <Typography variant='body1' color='textSecondary'>
              {`${currentPage}/${Math.ceil(totalRows / rowsPerPage)}`}
            </Typography>
            <Button
              sx={{
                borderRadius: '10px',
                border: '1px solid gray',
                minWidth: '20px',
                bgcolor: '#F7F9FC',
              }}
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(prev + 1, Math.ceil(totalRows / rowsPerPage))
                )
              }
              disabled={currentPage === Math.ceil(totalRows / rowsPerPage)}
            >
              <KeyboardArrowRightIcon
                sx={{ fontSize: '15px', fontWeight: '500' }}
              />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Paginations;
