import React from "react";
import HomeLayout from "../asset/layout/HomeLayout";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function Lead({ data = [] }) {
  return (
    <HomeLayout>
      <Box>
        <Typography variant="h4">Leads</Typography>
      </Box>
      <Box my={4}>
        <TableContainer component={Paper} sx={{ borderRadius: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#f0f0f0", fontWeight: 600 }}
                >
                  First Name
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#f0f0f0", fontWeight: 600 }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#f0f0f0", fontWeight: 600 }}
                >
                  Phone Number
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#f0f0f0", fontWeight: 600 }}
                >
                  Email
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#f0f0f0", fontWeight: 600 }}
                >
                  Company Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </HomeLayout>
  );
}
