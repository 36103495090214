export const navigateTo = (navigate, url) => {
  navigate(url);
  window.scrollTo(0, 0);
};


export const formatUTCDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = String(date.getUTCFullYear());
  return `${day}/${month}/${year}`;
};