import React, { useState, useEffect } from 'react';
import RModal from './mui/RModal';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import InputField1 from './mui/InputField1';
import DropDownMenu from './mui/DropDownMenu';
import { Stack } from 'react-bootstrap';
import CustomButton from './mui/CustomButton';
import SelectDropdown from './mui/SelectDropdown';
import { useDispatch } from 'react-redux';
import { getAllUsersClient } from '../actions/userAuth';
import { UpdateTheTicket, getAllTicket } from '../actions/ticketAuth';
import { enqueueSnackbar } from 'notistack';

const menuItemsAllotedTime = [
  { value: 'day', label: 'Day' },
  { value: 'hr', label: 'Hour' },
  { value: 'min', label: 'Minute' },
];
const TicketStatus = ['open', 'close', 'hold'];
const PriorityStatus = ['high', 'low', 'moderate'];

const AddTicketModal = ({ userId, ticketId, createdId, open, handleClose }) => {
  const [ticketNameEdit, setTicketNameEdit] = useState('');
  const [ticketDescriptionEdit, setTicketDescriptionEdit] =
    useState('');
  const [assignedToAddEdit, setAssignedToAddEdit] = useState([]);
  const [ticketStatusEdit, setTicketStatusEdit] = useState('');
  const [allotedTimeEdit, setAllotedTimeEdit] = useState('');
  const [priorityEdit, setPriorityEdit] = useState('');
  const [numberEdit, setNumberEdit] = useState(''); 
  const [unitEdit, setUnitEdit] = useState('');
  const [error, setError] = useState('');
  const [errorEdit, setErrorEdit] = useState('');
  const [unit, setUnit] = useState('hr');
  const [loading, setLoading] = useState(false);
  const [user_new, setUser_new] = useState([]);
  const [users, setUsers] = useState([]);
  const [allBackendUser, setAllBackendUser] = useState(25);

  const [number, setNumber] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const handleUnitChange = (event) => {
    if (event && event.target) {
      setUnit(event.target.value);
      setNumber('');
      setError('');
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setLoading(true);
    const formDataEdit = {
      ticketName: ticketNameEdit,
      ticketDescription: ticketDescriptionEdit,
      assignedTo: assignedToAddEdit,
      number: numberEdit,
      status: ticketStatusEdit,
      priority: priorityEdit,
      unit: unitEdit,
    };

    const fetchAdminUsers = () => {
      setLoading(true);
      dispatch(
        getAllTicket(
          userId,
          (data) => {
            const tickets = data.data.data.tickets || [];
            setUsers(Array.isArray(tickets) ? tickets : []);
            console.log('ticket data is', tickets);
            setLoading(false);
            setAllBackendUser(tickets.length);
          },
          (err) => {
            console.log(err);
            setLoading(false);
          }
        )
      );
    };

    const assignedToIds = assignedToAddEdit
      .map((item) => {
        const user = user_new.find(
          (user) => user.username === item || user.phone === item
        );
        return user ? user.id : null;
      })
      .filter((id) => id !== null);

    const updatedFormDataEdit = {
      ...formDataEdit,
      assignedTo: assignedToIds,
    };

    dispatch(
      UpdateTheTicket(
        updatedFormDataEdit,
        ticketId,
        createdId,
        (response) => {
          enqueueSnackbar('Data submitted successfully', {
            variant: 'success',
          });

          fetchAdminUsers();
          handleClose();
          setLoading(false);
        },
        (error) => {
          enqueueSnackbar('Error submitting data', {
            variant: 'error',
          });
          console.error(error);
          setLoading(false);
        }
      )
    );
  };

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllUsersClient(
        (data) => {
          const filteredData = data.data.data.users.filter(
            (item) => item
          );
          filteredData.sort(
            (a, b) =>
              new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUser_new(filteredData);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const autoCompleteOptions = user_new.map((user) => {
    if (user.username !== '') {
      return user.username;
    } else if (user.email !== '') {
      return user.email;
    } else {
      return user.phone;
    }
  });

  return (
    <RModal
      open={open}
      handleClose={handleClose}
      width={{ md: '60%', xs: '80%' }}
      height={{ md: '80%', xs: '80%' }}
    >
      <form onSubmit={handleEdit}>
        <Typography variant='h4'>Update Ticket</Typography>

        <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
          <Grid item xl={6} xs={12} sm={12}>
            <InputField1
              placeholderColor=' #8D98AA'
              label={'Ticket Name'}
              placeholder={'Enter Ticket Name'}
              spanP={true}
              value={ticketNameEdit}
              onChange={(e) => setTicketNameEdit(e.target.value)}
              required
              maxLength={20}
              disabled
            />
          </Grid>
          <Grid item xl={6} xs={12} sm={12}>
            <InputField1
              placeholderColor=' #8D98AA'
              label={'Ticket Description'}
              placeholder={'Enter Ticket Description'}
              spanP={true}
              value={ticketDescriptionEdit}
              onChange={(e) =>
                setTicketDescriptionEdit(e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xl={6} xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <InputField1
                  placeholderColor=' #8D98AA'
                  label={'Alloted Time'}
                  placeholder={'Enter Alloted Time'}
                  spanP={true}
                  required
                  type='number'
                  value={numberEdit}
                  onChange={(e) => setNumberEdit(e.target.value)}
                  disabled={unit === ''}
                />
                {error && (
                  <Typography color='error' variant='body2'>
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4}>
                <Box mt={6}>
                  <SelectDropdown
                    buttonText='Select Time Unit'
                    menuItems={menuItemsAllotedTime}
                    selectedItem={unitEdit}
                    onChange={handleUnitChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={6} xs={12} sm={12}>
            <Typography variant='h5' my={1.5}>
              Assigned to
            </Typography>
            <Autocomplete
              multiple
              value={assignedToAddEdit}
              id='tags-filled'
              options={autoCompleteOptions}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                const transformedValue = newValue
                  .map((item) => {
                    const user = user_new.find(
                      (user) =>
                        user.username === item ||
                        user.phone === item
                    );
                    return user ? user.id : null;
                  })
                  .filter((id) => id !== null);
                setAssignedToAddEdit(transformedValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Assigned To'
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '2.5rem',
                      background: '#FFFFFF',
                      borderRadius: '7px',
                      paddingRight: '8px',
                      py: 'auto',
                    },
                    '& .MuiInputBase-input': {
                      mt: '-12px',
                    },
                    '& .MuiAutocomplete-tag': {
                      mt: '-7px !important',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={6} xs={12} sm={12}>
            <Typography variant='h5' my={2}>
              Ticket Status
            </Typography>
            <DropDownMenu
              buttonText='Ticket Status'
              menuItems={TicketStatus.map((item) => ({
                label: item,
              }))}
              selectedItem={
                ticketStatusEdit
                  ? { label: ticketStatusEdit }
                  : null
              }
              setSelectedItem={setTicketStatusEdit}
              buttonWidth={'100%'}
            />
          </Grid>
          <Grid item xl={6} xs={12} sm={12}>
            <Typography variant='h5' my={2}>
              Priority
            </Typography>
            <DropDownMenu
              buttonText='Priority'
              menuItems={PriorityStatus.map((item) => ({
                label: item,
              }))}
              selectedItem={
                priorityEdit ? { label: priorityEdit } : null
              }
              setSelectedItem={setPriorityEdit}
              buttonWidth={'100%'}
            />
          </Grid>
        </Grid>
        <Stack
          direction={'row'}
          spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
          mt={4}
        >
          <CustomButton
            variant='contained'
            width={{
              xl: '20vh',
              md: '50%',
              sm: '50%',
              xs: '60%',
            }}
            height={'3rem'}
            type='submit'
          >
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </CustomButton>
        </Stack>
      </form>
    </RModal>
  );
};

export default AddTicketModal;
