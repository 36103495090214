// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* globalStyles.css */

body {
  font-family: 'Inter', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select::-moz-focus-outer, select::-moz-focus-inner{
  outline: none;
}

select:focus{
  outline: none;
}
.MuiOutlinedInput-input {
  padding: 10px !important;
  display: flex;
  align-items: center;
  outline: none !important;
}

th, td{
  text-align: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/globalStyles.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,gCAAgC;AAClC;;AAEA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["/* globalStyles.css */\n\nbody {\n  font-family: 'Inter', sans-serif;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\nselect::-moz-focus-outer, select::-moz-focus-inner{\n  outline: none;\n}\n\nselect:focus{\n  outline: none;\n}\n.MuiOutlinedInput-input {\n  padding: 10px !important;\n  display: flex;\n  align-items: center;\n  outline: none !important;\n}\n\nth, td{\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
